@charset "utf-8"; 

.c-heading-xxl {
	line-height: 1.5;
	@include fontSize(22,14);
	@include mqXL(&){
		@include fontSize(28);
	}
}

.c-heading-xl {
	line-height: 1.5;
	@include fontSize(20,14);
	@include mqXL(&){
		@include fontSize(24);
	}
}

.c-heading-l {
	line-height: 1.5;
	@include fontSize(18,14);
	@include mqXL(&){
		@include fontSize(20);
	}
}

.c-heading-m {
	line-height: 1.5;
	@include fontSize(16,14);
	@include mqXL(&){
		@include fontSize(18);
	}
}

.c-heading-s {
	line-height: 1.5;
	@include fontSize(14,14);
	@include mqXL(&){
		@include fontSize(16);
	}
}

