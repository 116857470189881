@charset "utf-8";

/* --- インナー ---
-------------------------------------------------------*/

.l-inner {
	padding: 0 20px;
	@include mqLG(&){
		padding: 0 80px;
		max-width: 1360px;
		margin: 0 auto;
	}
	&--narrow {
		@include mqLG(&){
//			max-width: 960px;
			max-width: 1120px;
		}
	}
}

.l-grid-col2-4 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px -32px;
	@include mqMD(&){
		margin: 0 -10px -32px;
	}
	@include mqLG(&){
		margin: 0 -12px -32px;
	}
	@include mqXL(&){
		margin: 0 -16px -64px;
	}
}

.l-col2-4 {
	width: 50%;
	padding: 0 8px;
	margin-bottom: 32px;
	@include mqMD(&){
		width: 25%;
		padding: 0 10px;
	}
	@include mqLG(&){
		padding: 0 12px;
	}
	@include mqXL(&){
		padding: 0 16px;
		margin-bottom: 64px;
	}
}

.l-grid-col2 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px -32px;
	@include mqMD(&){
		margin: 0 -10px -32px;
	}
	@include mqLG(&){
		margin: 0 -12px -32px;
	}
	@include mqXL(&){
		margin: 0 -16px -64px;
	}
}

.l-col2 {
	width: 50%;
	padding: 0 8px;
	margin-bottom: 32px;
	@include mqMD(&){
		padding: 0 10px;
	}
	@include mqLG(&){
		padding: 0 12px;
	}
	@include mqXL(&){
		padding: 0 16px;
		margin-bottom: 64px;
	}
}