@charset "utf-8"; 

.c-list {
	> li {
		line-height: 1.5;
		margin-bottom: .5em;
	}
	
	&--dots {
		> li {
			position: relative;
			padding-left: 1em;
			&:before {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $borderColor;
				position: absolute;
				top: .5em;
				left: 0;
			}
		}
	}
	
	&--num {
		counter-reset: num;
		> li {
			counter-increment: num;
			padding-left: 1.75em;
			&:before {
				content: counter(num)" ).";
				font-weight: 600;
				width: 1.75em;
				margin-left: -1.75em;
				display: inline-block;
			}
		}
	}
}

