@charset "utf-8"; 

/* --- Swiper 基本設定 ---
-------------------------------------------------------*/

.swiper-container {
	transition:opacity .6s ease, transform .3s ease;
	&.is-loading{
		opacity:0;
		visibility:hidden;
	}
}

.swiper-wrapper {

}

.swiper-button {
	display: flex;
	justify-content: flex-end;
	&.is-hide {
		display: none;
	}
}

.swiper-button-next {
	width: 44px;
	height: 44px;
	background-color: $mainColor;
	@include arrow_right(7px,12px,$whiteColor);
	background-position: center center;
	z-index: 15;
	cursor: pointer;
	@include mqLG(&){
		width: 48px;
		height: 48px;
	}
	@include mqXL(&){
		width: 56px;
		height: 56px;
		background-size: 10px 16px;
	}
}
.swiper-button-prev {
	width: 44px;
	height: 44px;
	margin-right: 1px;
	background-color: $mainColor;
	@include arrow_left(7px,12px,$whiteColor);
	background-position: center center;
	z-index: 15;
	cursor: pointer;
	@include mqLG(&){
		width: 48px;
		height: 48px;
	}
	@include mqXL(&){
		width: 56px;
		height: 56px;
		background-size: 10px 16px;
	}
}

.swiper-button-disabled {
	background-color: $borderColor;
}

.swiper-pagination {
	@include fontSize(20,14);
	color: rgba($txtColor,.5);
	font-weight: 700;
	&-current {
		color: $mainColor;
	}
	&-total {

	}
	&.is-hide {
		display: none;
	}
}

/* --- Carousel ---
-------------------------------------------------------*/

.js-carouselType1 {
	padding-right: 20px;
	@include mqXL(&){
		padding-right: 0;
	}
	.swiper-wrapper {
//		margin-left: -26/300*100%;
//		@include mqSM(&){
//			margin-left: -128.727/460*100%;
//			margin-left: 0;
//		}
	}
	.swiper-slide {
//		&:not(.swiper-slide-active) {
//			a {
//				pointer-events: none;
//			}
//			.c-card-items-type1 {
//				background-color: rgba($borderColor,.1);
//			}
//			.c-card-items-type1__header {
//				position: relative;
//				&:before {
//					content:"";
//					display: block;
//					position: absolute;
//					width: 100%;
//					height: 100%;
//					top: 0;
//					left: 0;
//					background-color: rgba($blackColor,.75);
//				}
//			}
//			.c-card-items-type1__body {
//				color: $borderColor;
//				background-color: transparent;
//			}
//			.c-card-items-type1__btn {
//				border-color: $borderColor;
//			}
//
//			.c-card-items-type2 {
//				background-color: rgba($borderColor,.1);
//				> a {
//					color: $borderColor;
//				}
//			}
//			.c-card-items-type2__header {
//				position: relative;
//				&:before {
//					content:"";
//					display: block;
//					position: absolute;
//					width: 100%;
//					height: 100%;
//					top: 0;
//					left: 0;
//					background-color: rgba($blackColor,.75);
//				}
//			}
//			.c-card-items-type2__btn {
//				border-color: $borderColor;
//			}
//		}
	}
	.swiper-button {
		margin-top: 20px;
//		padding-right: 20px;
	}
	.swiper-pagination {
		text-align: left;
//		padding-left: 20px;
	}
}

.js-carouselType2 {
	padding-right: 20px;
	@include mqLG(&){
		padding-right: 0;
	}
	.swiper-wrapper {
	}
	.swiper-slide {
	}
	.swiper-button {
		margin-top: 20px;
		&.is-hide {
			margin-top: 0;
		}
	}
	.swiper-pagination {
		text-align: left;
		@include mqMD(&){
			padding-left: 20px;
		}
	}
}

.js-carouselType3 {
	padding-right: 20px;
	@include mqLG(&){
		padding-right: 0;
	}
	.swiper-wrapper {
	}
	.swiper-slide {
	}
	.swiper-button {
		margin-top: 20px;
	}
	.swiper-pagination {
		text-align: left;
		@include mqMD(&){
			padding-left: 20px;
		}
	}
}

/* --- Gallery ---
-------------------------------------------------------*/

#js-gallery-top {
	.swiper-slide {
		overflow: hidden;
	}
}

#js-gallery-thumbs {
	padding-top: 10px;
	@include mqMD(&){
		padding-top: 16px;
	}
	@include mqLG(&){
		padding-top: 24px;
	}
	@include mqXL(&){
		padding-top: 32px;
	}
	
	&.is-hide {
		display: none;
	}
	
	.swiper-slide {
		transition: opacity 300ms ease;
		cursor: pointer;
		&:not(.swiper-slide-thumb-active) {
			@include opacity(.7);
		}
	}
}

