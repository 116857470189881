@charset "utf-8";

//***********************
// fontSize
//***********************

@mixin fontSize($size: 24, $base:16) {
	font-size: $size +px;
	font-size: ($size / $base) * 1rem;
}

//***********************
// mediaquery
//***********************
$xsBreakPoint: 479px;
$smBreakPoint: 480px;
$mdBreakPoint: 600px;
$lgBreakPoint: 960px;
$xlBreakPoint: 1280px;
$xxlBreakPoint: 1480px;


// スマホ専用出力
@mixin mqXS ($selector) {
	@media (max-width: $xsBreakPoint) {
		@content;
	}
}

// スマホ横
@mixin mqSM ($selector) {
	@media (min-width: $smBreakPoint ){
		@content;
	}
}

// タブレット縦
@mixin mqMD ($selector) {
	@media (min-width: $mdBreakPoint) {
		@content;
	}
}

// 小さいPC・タブレット横
@mixin mqLG ($selector) {
	@media (min-width: $lgBreakPoint) {
		@content;
	}
}

// PC
@mixin mqXL ($selector) {
	@media (min-width: $xlBreakPoint) {
		@content;
	}
}

@mixin mqXXL ($selector) {
	@media (min-width: $xxlBreakPoint) {
		@content;
	}
}

//***********************
// clearfix
//***********************
@mixin cf {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

//***********************
// opacity
//***********************
@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}

/**
* SVG画像をアイコンに使いたい的ないろいろ
*
*  [Html] SVG塗りつぶしの色をBackground-Imageとして扱うときに変更する
* https://code.i-harness.com/ja/q/cbfa3c
*/
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}
@function url-encode($string) {
	$map: (
		"%": "%25",
		"<": "%3C",
		">": "%3E",
		" ": "%20",
		"!": "%21",
		"*": "%2A",
		"'": "%27",
		'"': "%22",
		"(": "%28",
		")": "%29",
		";": "%3B",
		":": "%3A",
		"@": "%40",
		"&": "%26",
		"=": "%3D",
		"+": "%2B",
		"$": "%24",
		",": "%2C",
		"/": "%2F",
		"?": "%3F",
		"#": "%23",
		"[": "%5B",
		"]": "%5D"
	);
	$new: $string;
	@each $search, $replace in $map {
		$new: str-replace($new, $search, $replace);
	}
	@return $new;
}

@function inline-svg($string) {
//	@return url('data:image/svg+xml;utf8,#{url-encode($string)}');
	@return url('data:image/svg+xml,#{url-encode($string)}');
}

// 各種アイコン

//下矢印
@mixin arrow_down($width:24px, $height:24px, $color:$txtColor) {
	background-image: inline-svg(
		'<svg xmlns="http://www.w3.org/2000/svg" width="10.707" height="6.061" viewBox="0 0 10.707 6.061">'
		+ '<path d="M10.354.354l-5,5-5-5" transform="translate(0 0)" fill="none" stroke="' + $color + '" stroke-miterlimit="10" stroke-width="1"/>'
		+ '</svg>');
	background-size: $width $height;
}

//右矢印
@mixin arrow_right($width:24px, $height:24px, $color:$txtColor) {
	background-image: inline-svg(
		'<svg xmlns="http://www.w3.org/2000/svg" width="6.061" height="10.707" viewBox="0 0 6.061 10.707">'
		+ '<path d="M10.354.354l-5,5-5-5" transform="translate(0 10.708) rotate(-90)" fill="none" stroke="' + $color + '" stroke-miterlimit="10" stroke-width="1"/>'
		+ '</svg>');
	background-size: $width $height;
}



//左矢印
@mixin arrow_left($width:24px, $height:24px, $color:$txtColor) {
	background-image: inline-svg(
		'<svg xmlns="http://www.w3.org/2000/svg" width="6.061" height="10.707" viewBox="0 0 6.061 10.707">'
		+ '<path d="M10.354.354l-5,5-5-5" transform="translate(6.061 0) rotate(90)" fill="none" stroke="' + $color + '" stroke-miterlimit="10" stroke-width="1"/>'
		+ '</svg>');
	background-size: $width $height;
}