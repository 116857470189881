@charset "utf-8";

.p-header {
    $self: &;
    &.is-open {
        padding-top: 64px;
        @include mqXXL(&) {
            padding-top: 0;
        }
    }
    &.is-top {
        #{$self}__inner {
            background-color: transparent;
            @include mqXXL(&) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
            }
            &.is-open {
                background-color: $bgColor;
                @include mqXXL(&) {
                    background-color: transparent;
                }
                #{$self}__logo {
                    @include opacity(1);
                    @include mqXXL(&) {
                        @include opacity(0);
                    }
                }
            }
        }
        #{$self}__logo {
            @include opacity(0);
        }
        .p-gnav__menu {
            > li {
                > a {
                    @include mqXXL(&) {
                        color: $whiteColor;
                        &:after {
                            background-color: rgba($whiteColor, 0.8);
                        }
                    }
                }
                > span {
                    @include mqXXL(&) {
                        color: $whiteColor;
                    }
                }
            }
        }

        .p-header__link__sns {
            > li {
                > a {
                    @include mqXXL(&) {
                        width: 56px;
                        height: 56px;
                        color: $whiteColor;
                        background-color: rgba($borderColor, 0.8);
                        &:hover {
                            background-color: rgba($borderColor, 0.6);
                        }
                    }
                }
            }
        }
    }

    &.is-small {
        #{$self}__inner {
            @include mqXXL(&) {
                height: 56px;
            }
        }
        #{$self}__logo {
            @include mqXXL(&) {
                width: 228px;
                height: 40px;
            }
        }

        .p-gnav__menu {
            > li {
                > a {
                    @include mqXXL(&) {
                        height: 56px;
                    }
                }
                > span {
                    @include mqXXL(&) {
                        height: 56px;
                    }
                }
            }
        }

        .p-header__link__sns {
            > li {
                > a {
                    @include mqXXL(&) {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
        }

        .p-header__link__online-shop {
            > a {
                @include mqXXL(&) {
                    height: 56px;
                    padding: 0 22px;
                }
            }
        }
    }

    &.is-fixed {
        &:not(.js-header-home) {
            @include mqXXL(&) {
                padding-top: 100px;
            }
        }
        #{$self}__inner {
            @include mqXXL(&) {
                position: fixed;
                top: 0;
                background-color: $whiteColor;
            }
        }
        .p-header__menu {
            @include mqXXL(&) {
                position: fixed;
                z-index: 101;
            }
        }
    }

    &__inner {
        position: relative;
        height: 64px;
        z-index: 100;
        background-color: $bgColor;
        @include mqLG(&) {
            height: 64px;
        }
        @include mqXL(&) {
        }
        @include mqXXL(&) {
            position: static;
            width: 100%;
            height: 100px;
        }
        &.is-open {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            @include mqXXL(&) {
                position: static;
            }
        }
    }
    &__logo {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        width: 170px;
        height: 30px;
        @include mqLG(&) {
            width: 170 * 1.2 + px;
            height: 30 * 1.2 + px;
            left: 32px;
        }
        @include mqXXL(&) {
            width: 272px;
            height: 48px;
            left: 80/1920 * 100vw;
        }
        a {
            display: block;
            img {
                display: block;
            }
        }
    }
    &__btn {
        width: 64px;
        height: 64px;
        position: fixed;
        right: 0;
        top: 0;
        border: none;
        background-color: #30597e;
        z-index: 100;
        @include mqXXL(&) {
            display: none;
        }
        %gnaviTriggerCenter {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            background-color: $whiteColor;
            transform: none;
            transition: transform $ease-out-quint 300ms;
        }
        > span {
            display: block;
            width: 20px;
            height: 1px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            transition: all $ease-out-quint 300ms;
            &:first-child {
                top: -12px;
                bottom: 0;
                background-color: $whiteColor;
            }
            &:nth-child(2) {
                top: 0;
                bottom: 0;
                &:before {
                    @extend %gnaviTriggerCenter;
                }
                &:after {
                    @extend %gnaviTriggerCenter;
                }
            }
            &:nth-child(3) {
                top: 0;
                bottom: -12px;
                background-color: $whiteColor;
            }
        }
        &.is-active {
            > span {
                &:first-child {
                    width: 0;
                    left: 100%;
                }
                &:nth-child(2) {
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(135deg);
                    }
                }
                &:nth-child(3) {
                    width: 0;
                    right: 100%;
                }
            }
        }
        &.is-disable {
            pointer-events: none;
        }
    }
    &__menu {
        @include opacity(0);
        visibility: hidden;
        position: fixed;
        top: 64px;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 98;
        transition: opacity $ease-out-quint 200ms;
        @include mqXXL(&) {
            display: flex;
            @include opacity(1);
            visibility: visible;
            position: absolute;
            width: auto;
            height: auto;
            top: 0;
            left: auto;
            right: 0;
            overflow: visible;
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 200px;
            @include mqXXL(&) {
                content: none;
            }
        }

        &.is-open {
            @include opacity(1);
            visibility: visible;
            transition: opacity $ease-out-quint 150ms 200ms;
        }
    }
}

.p-gnav {
    $self: &;
    padding: 24px 10px 20px;
    @include mqMD(&) {
        padding: 24px 20px 20px;
    }
    @include mqLG(&) {
        padding: 24px 80px 64px;
    }
    @include mqXXL(&) {
        padding: 0 14px 0 0;
    }
    &__menu {
        @include mqXXL(&) {
            display: flex;
        }
        > li {
            @include fontSize(16, 14);
            font-weight: 700;
            border-bottom: 1px solid $borderColor;
            @include mqXXL(&) {
                width: auto;
                @include fontSize(16);
                border: none;
                &:hover {
                    > a {
                        &:after {
                            transform: scale(1, 1);
                        }
                    }
                }
            }
            > a {
                display: block;
                padding: 13px 16px;
                transition: background-color 300ms ease, color 300ms ease;
                @include mqMD(&) {
                    padding: 16px;
                }
                @include mqLG(&) {
                    padding: 21px 20px 22px;
                }
                @include mqXXL(&) {
                    display: flex;
                    align-items: center;
                    padding: 14px;
                    height: 100px;
                    position: relative;
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background-color: $mainColor;
                        transform: scale(0, 1);
                        transition: transform 300ms $ease-out-quint;
                    }
                }
                &:hover {
                    text-decoration: none;
                    color: $whiteColor;
                    background-color: darken($bgColor, 30);
                    @include mqXXL(&) {
                        color: $txtColor;
                        background-color: transparent;
                    }
                }
            }

            &.is-current {
                > a {
                    @include mqXXL(&) {
                        &:after {
                            transform: scale(1, 1);
                        }
                    }
                }
            }

            &#{$self}__menu__has-child {
                @include mqXXL(&) {
                    position: relative;
                }
                > a {
                    display: none;
                    @include mqXXL(&) {
                        display: flex;
                    }
                }

                > span {
                    display: block;
                    padding: 13px 16px;
                    position: relative;
                    cursor: pointer;
                    transition: background-color 300ms ease, color 300ms ease;
                    @include mqMD(&) {
                        padding: 16px;
                    }
                    @include mqLG(&) {
                        padding: 21px 20px 22px;
                    }
                    @include mqXXL(&) {
                        display: none;
                    }
                    &:hover {
                        color: $whiteColor;
                        background-color: darken($bgColor, 30);
                        &:before {
                            transition: background-color 300ms ease;
                            background-color: $whiteColor;
                        }
                        &:after {
                            transition: background-color 300ms ease;
                            background-color: $whiteColor;
                        }
                    }
                    &:before {
                        content: '';
                        display: block;
                        background-color: $txtColor;
                        height: 1px;
                        width: 20px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 16px;
                        margin: auto;
                        @include mqXXL(&) {
                            content: none;
                        }
                    }
                    &:after {
                        content: '';
                        display: block;
                        background-color: $txtColor;
                        height: 20px;
                        width: 1px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 25px;
                        margin: auto;
                        @include mqXXL(&) {
                            content: none;
                        }
                    }
                    &.is-active {
                        color: $whiteColor;
                        background-color: darken($bgColor, 30);
                        &:before {
                            background-color: $whiteColor;
                        }
                        &:after {
                            @include opacity(0);
                        }
                    }
                }
            }
        }

        &__child {
            display: none;
            @include mqXXL(&) {
                position: absolute;
                top: 100%;
                left: 0;
                white-space: nowrap;
                display: block;
                @include opacity(0);
                visibility: hidden;
                transition: 300ms opacity ease, 300ms visibility ease;
            }
            &.is-hover {
                @include mqXXL(&) {
                    @include opacity(1);
                    visibility: visible;
                }
            }
            > li {
                &:not(:last-child) {
                    border-bottom: 1px solid rgba($borderColor, 0.5);
                    @include mqXXL(&) {
                        border: none;
                    }
                }
                > a {
                    display: block;
                    color: rgba($txtColor, 0.75);
                    padding: 13px 16px;
                    transition: background-color 300ms ease, color 300ms ease;
                    @include mqMD(&) {
                        padding: 16px;
                    }
                    @include mqLG(&) {
                        padding: 21px 20px 22px;
                    }
                    @include mqXXL(&) {
                        color: $whiteColor;
                        background-color: $blackColor;
                    }
                    &:hover {
                        color: $whiteColor;
                        text-decoration: none;
                        background-color: darken($bgColor, 20);
                        @include mqXXL(&) {
                            background-color: lighten($blackColor, 5);
                        }
                    }
                }
            }
        }
    }

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        @include opacity(0);
        visibility: hidden;
        background-color: rgba($bgColor, 0.9);
        transition: opacity $ease-out-quint 100ms, visibility $ease-out-quint 100ms;
        z-index: 95;
        &.is-open {
            @include opacity(1);
            visibility: visible;
            @include mqXXL(&) {
                @include opacity(0);
                visibility: hidden;
            }
        }
    }
}

.p-header {
    &__link {
        padding: 0 10px;
        @include mqMD(&) {
            padding: 0 20px;
            display: flex;
            justify-content: flex-end;
        }
        @include mqLG(&) {
            padding: 0 80px;
        }
        @include mqXXL(&) {
            padding: 0;
            justify-content: flex-start;
        }
        &__sns {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 16px;
            @include mqXXL(&) {
                margin-bottom: 0;
            }
            > li {
                margin-right: 8px;
                @include mqXXL(&) {
                    margin-right: 1px;
                }
                > a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 44px;
                    height: 44px;
                    color: $whiteColor;
                    background-color: $blackColor;
                    transition: background-color 300ms ease;
                    @include mqLG(&) {
                        width: 64px;
                        height: 64px;
                    }
                    @include mqXXL(&) {
                        width: 100px;
                        height: 100px;
                        @include fontSize(24);
                        color: $txtColor;
                        background-color: rgba($borderColor, 0.3);
                    }

                    &:hover {
                        text-decoration: none;
                        background-color: rgba($blackColor, 0.8);
                        @include mqXXL(&) {
                            background-color: rgba($borderColor, 0.5);
                        }
                    }
                }
            }
        }
        &__online-shop {
            > a {
                display: block;
                color: $whiteColor;
                text-align: center;
                padding: 13px 16px;
                background-color: #30597e;
                transition: background-color 300ms ease;
                @include mqLG(&) {
                    padding: 23px 20px;
                }
                @include mqXXL(&) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 44px;
                    height: 100px;
                }
                &:hover {
                    text-decoration: none;
                    background-color: lighten(#30597e, 5);
                }
                i {
                    margin-right: 0.5em;
                }
            }
        }
    }
}

/*------------------------------------------------------

   --- トップページ ---

-------------------------------------------------------*/

/* --- メインビジュアル ---
-------------------------------------------------------*/

.p-mainvisual {
    $self: &;
    position: relative;
    margin-top: -64px;
    height: 410px;
    overflow: hidden;
    @include mqMD(&) {
        height: 520px;
    }
    @include mqXL(&) {
        height: 880px;
    }
    &__cnts {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba($blackColor, 0.2);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(./images/top/mainvisual.jpg);
        background-size: cover;
        background-position: center center;
        z-index: -1;
        @include mqXL(&) {
            transition: transform 12000ms linear;
        }
        &.is-scale {
            @include mqXL(&) {
                transform: scale(1.1, 1.1) rotate(0.1deg);
            }
        }
    }
    &__inner {
        @include mqXL(&) {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: 28px;
            transform: translateY(-50%);
            padding-left: 280/1920 * 100%;
        }
    }
    &__logo {
        width: 264px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include mqMD(&) {
            width: 320px;
            left: 280/1920 * 100%;
            transform: none;
        }
        @include mqXL(&) {
            width: 443px;
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            margin-bottom: 64px;
        }
    }
    &__catch {
        color: $whiteColor;
        font-weight: 700;
        @include fontSize(15, 14);
        line-height: 1.75;
        letter-spacing: 0.08em;
        border: 1px solid $whiteColor;
        padding: 17px 10px;
        position: absolute;
        bottom: 28px;
        left: 50%;
        width: calc(100% - 40px);
        transform: translateX(-50%);
        @include mqSM(&) {
            max-width: 360px;
            padding: 20px;
        }
        @include mqMD(&) {
            border: none;
            max-width: none;
            @include fontSize(18, 14);
            top: 50%;
            left: 280/1920 * 100%;
            bottom: auto;
            width: auto;
            margin-top: 90px;
            padding: 0;
            transform: translate(0, -50%);
        }
        @include mqXL(&) {
            position: relative;
            top: 0;
            left: 0;
            margin-top: 0;
            transform: none;
            @include fontSize(24);
            padding: 0 32px;
        }

        > span {
            display: table;
            margin: 0 auto;
            @include mqXL(&) {
                display: block;
            }
        }
    }
}

/* --- トップ 無天茶坊について ---
-------------------------------------------------------*/
.p-top-about {
    position: relative;
    margin-bottom: 64px;
    @include mqXL(&) {
        margin-bottom: 96px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url(./images/top/section-about-bg.jpg);
        background-size: 100% 100%;
        z-index: -1;
        width: 100%;
        height: 0;
        padding-top: 256/320 * 100%;
        @include mqMD(&) {
            padding-top: 0;
            width: round(1110 * 0.4) + px;
            height: round(888 * 0.4) + px;
        }
        @include mqLG(&) {
            width: round(1110 * 0.6) + px;
            height: round(888 * 0.6) + px;
        }
        @include mqXL(&) {
            width: 1110px;
            height: 888px;
        }
    }
    &__desc {
        padding: 40px 0;
        @include mqXL(&) {
            padding: 96px 0 72px;
        }
        &__row {
            @include mqMD(&) {
                max-width: 640px;
                margin: 0 auto;
            }
            @include mqLG(&) {
                max-width: none;
                display: flex;
            }
        }
        &__header {
            margin-bottom: 32px;
            @include mqLG(&) {
                margin-bottom: 0;
                margin-right: 24px;
                width: 1/3 * 100%;
            }
        }
        &__body {
            @include mqLG(&) {
                flex: 1;
            }
        }
        &__pgh {
            @include mqXL(&) {
                &:not(:last-of-type) {
                    margin-bottom: 1em;
                }
            }
        }
    }
    &__list {
        &__inner {
            position: relative;
            padding-left: 20px;
            @include mqMD(&) {
                padding: 0 20px;
            }
            @include mqLG(&) {
                padding: 0 80px;
            }
            @include mqXL(&) {
                max-width: 1200px + 160px;
                margin: 0 auto;
            }
            .swiper-wrapper {
                @include mqMD(&) {
                    margin: 0 -10px;
                }
                @include mqLG(&) {
                    margin: 0 -12px;
                }
                @include mqXL(&) {
                    margin: 0 -16px;
                    width: auto;
                }
            }

            .swiper-slide {
                @include mqMD(&) {
                    width: 1/3 * 100%;
                    padding: 0 10px;
                }
                @include mqLG(&) {
                    padding: 0 12px;
                }
                @include mqXL(&) {
                    padding: 0 16px;
                    box-sizing: border-box;
                }
            }

            .swiper-pagination {
                @include mqMD(&) {
                    display: none;
                }
            }

            .swiper-button {
                @include mqMD(&) {
                    display: none;
                }
            }
        }
    }
    &__btn {
        padding: 0 20px;
        max-width: 360px;
        margin: 40px auto 0;
        @include mqXL(&) {
            margin-top: 64px;
        }
    }
}

/* --- トップ メニュー ---
-------------------------------------------------------*/

.p-top-menu {
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 204px;
        left: 0;
        background-image: url(./images/top/section-menu-bg.jpg);
        background-size: 100% 100%;
        z-index: -1;
        width: 100%;
        height: 0;
        padding-top: 204/320 * 100%;
        @include mqMD(&) {
            padding-top: 0;
            right: 0;
            margin: auto;
            bottom: 7%;
            width: round(1011 * 0.5) + px;
            height: round(644 * 0.5) + px;
        }
        @include mqLG(&) {
            bottom: 10%;
            margin: 0;
            left: -20px;
            right: auto;
            width: round(1011 * 0.6) + px;
            height: round(644 * 0.6) + px;
        }
        @include mqXL(&) {
            width: 1011px;
            height: 644px;
            left: -103px;
            bottom: -122px;
        }
    }
    &__header {
        position: relative;
        margin-bottom: 20px;
        @include mqLG(&) {
            height: 320px;
            margin: 0 0 40px 40px;
        }
        @include mqXL(&) {
            height: 416px;
            margin: 0 0 64px 0;
            padding-top: 56px;
            max-width: 2000px;
            left: 50%;
            transform: translateX(-50%);
            &:after {
                content: '';
                display: block;
                width: 40px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $bgColor;
            }
        }
        &__img {
            position: absolute;
            top: 0;
            right: 0;
            width: calc(100% - 64px);
            height: 172px;
            background-image: url(./images/top/section-img-01.jpg);
            background-size: cover;
            @include mqMD(&) {
                height: 0;
                padding-top: 172/600 * 100%;
            }
            @include mqLG(&) {
                padding-top: 0;
                height: 280px;
                width: 468/920 * 100%;
            }
            @include mqXL(&) {
                width: 50%;
                height: 360px;
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($blackColor, 0.3);
            }
        }
        &__bg {
            position: absolute;
            top: 20px;
            left: 0;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            background-color: $mainColor;
            z-index: -1;
            @include mqLG(&) {
                top: 40px;
                width: calc(100% - 80px);
                height: 280px;
            }
            @include mqXL(&) {
                display: none;
            }
        }
    }

    &__heading {
        color: $whiteColor;
        padding: 192px 0 24px;
        @include mqMD(&) {
            padding: 192/560 * 100% 0 24/560 * 100%;
        }
        @include mqLG(&) {
            padding: 40px 0 0 40px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        @include mqXL(&) {
            position: relative;
            top: 0;
            transform: none;
            display: flex;
            align-items: center;
            height: 360px;
            padding: 0 0 0 280/1200 * 100%;
            margin: 0 0 0 -280/1200 * 100%;
            background-color: $mainColor;
        }
    }

    &__season {
        margin-bottom: 48px;
        @include mqXL(&) {
            margin-bottom: 72px;
        }
        &__inner {
            padding-left: 20px;
            @include mqLG(&) {
                padding: 0 80px;
            }
            @include mqXL(&) {
                max-width: 1360px;
                margin: 0 auto;
            }
        }
    }

    &__list {
        margin-bottom: 48px;
        @include mqXL(&) {
            margin-bottom: 72px;
        }
        &__inner {
            padding: 0 20px;
            @include mqLG(&) {
                padding: 0 80px;
            }
            @include mqXL(&) {
                max-width: 1360px;
                margin: 0 auto;
            }
        }
        &__heading {
            margin-bottom: 28px;
            @include mqXL(&) {
                margin-bottom: 48px;
            }
        }
        &__items {
            margin-right: -20px;
            @include mqLG(&) {
                margin-right: 0;
            }
        }
    }
}

.p-top-menu__takeout {
    margin-bottom: 64px;
    @include mqXL(&) {
        margin-bottom: 96px;
    }
}

/* --- トップ ニュース ---
-------------------------------------------------------*/

.p-top-news {
    margin-bottom: 48px;
    @include mqXL(&) {
        margin-bottom: 72px;
    }
    &__header {
        position: relative;
        margin-bottom: 20px;
        @include mqMD(&) {
            margin-bottom: 40px;
        }
        @include mqLG(&) {
            height: 320px;
        }
        @include mqXL(&) {
            height: 416px;
            margin: 0 0 64px 0;
            max-width: 2000px;
            left: 50%;
            transform: translateX(-50%);
        }
        &__img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 64px);
            height: 172px;
            background-image: url(./images/top/section-img-02.jpg);
            background-size: cover;
            @include mqMD(&) {
                height: 0;
                padding-top: 172/600 * 100%;
            }
            @include mqLG(&) {
                padding-top: 0;
                height: 280px;
                width: 468/960 * 100%;
            }
            @include mqXL(&) {
                width: 50%;
                height: 360px;
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($blackColor, 0.3);
            }
        }
        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            background-color: $mainColor;
            z-index: -1;
            @include mqLG(&) {
                width: calc(100% - 160px);
                height: 280px;
            }
            @include mqXL(&) {
                display: none;
            }
        }
    }
    &__heading {
        color: $whiteColor;
        padding: 24px 0 192px;
        @include mqMD(&) {
            padding: 24/560 * 100% 0 192/560 * 100%;
        }
        @include mqLG(&) {
            padding: 0 0 40px 80px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        @include mqXL(&) {
            position: relative;
            top: 0;
            transform: none;
            display: flex;
            align-items: center;
            height: 360px;
            padding: 0 0 0 360/1200 * 100%;
            margin: 0 80px 0 -360/1200 * 100%;
            background-color: $mainColor;
        }
    }
    &__btn {
        padding: 0 20px;
        max-width: 360px;
        margin: 40px auto 0;
        @include mqXL(&) {
            margin-top: 64px;
        }
    }
}

/* --- トップ ブログ ---
-------------------------------------------------------*/

.p-top-blog {
    $self: &;
    position: relative;
    margin-bottom: 64px;
    @include mqXL(&) {
        margin-bottom: 96px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url(./images/top/section-news-bg.jpg);
        background-size: 100% 100%;
        z-index: -1;
        width: 100%;
        height: 0;
        padding-top: 261/320 * 100%;
        @include mqMD(&) {
            padding-top: 0;
            bottom: -120px;
            width: round(1095 * 0.4) + px;
            height: round(893 * 0.4) + px;
        }
        @include mqLG(&) {
            width: round(1095 * 0.6) + px;
            height: round(893 * 0.6) + px;
        }
        @include mqXL(&) {
            width: 1095px;
            height: 893px;
            bottom: -127px;
            right: 0;
        }
    }
    &__heading {
        margin-bottom: 16px;
        @include mqMD(&) {
            margin-bottom: 28px;
        }
        @include mqXL(&) {
            margin-bottom: 48px;
        }
    }
    &__btn {
        padding: 0 20px;
        max-width: 360px;
        margin: 40px auto 32px;
        @include mqXL(&) {
            margin: 64px auto;
        }
    }
    &__bnr {
        padding: 0 20px;
        @include mqLG(&) {
            padding: 0 80px;
            max-width: 1360px;
            margin: 0 auto;
        }
        &__heading {
            margin-bottom: 4px;
        }
        &__pgh {
            font-weight: 700;
            @include mqXL(&) {
                font-weight: 400;
            }
        }
        > a {
            display: block;
            height: 120px;
            position: relative;
            overflow: hidden;
            @include mqMD(&) {
                width: calc(50% - 10px);
                height: 0;
                padding-top: 120/600 * 100%;
            }
            @include mqXL(&) {
                height: 200px;
                padding-top: 0;
            }
            &:hover {
                text-decoration: none;
                #{$self}__bnr__img {
                    transform: scale(1.1, 1.1);
                }
            }
        }

        &__body {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            color: $whiteColor;
            text-align: center;
            transform: translateY(-50%);
        }

        &__img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(./images/top/bnr-01.jpg);
            background-size: cover;
            background-position: center center;
            transition: transform 700ms ease;
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($blackColor, 0.65);
            }
        }
    }
}

/* --- 動画 ---
-------------------------------------------------------*/

.p-top-movie {
    margin-bottom: 64px;
    &__header {
        position: relative;
        margin-bottom: 40px;
        @include mqLG(&) {
            height: 320px;
            margin: 0 0 40px 40px;
        }
        @include mqXL(&) {
            height: 416px;
            margin: 0 0 64px 0;
            max-width: 2000px;
            left: 50%;
            transform: translateX(-50%);
            &:after {
                content: '';
                display: block;
                width: 40px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $bgColor;
            }
        }
        &__img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 64px);
            height: 172px;
            background-image: url(./images/top/section-img-03.jpg);
            background-size: cover;
            @include mqMD(&) {
                height: 0;
                padding-top: 172/600 * 100%;
            }
            @include mqLG(&) {
                padding-top: 0;
                height: 280px;
                width: 468/920 * 100%;
            }
            @include mqXL(&) {
                width: 50%;
                height: 360px;
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($blackColor, 0.3);
            }
        }
        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            background-color: $mainColor;
            z-index: -1;
            @include mqLG(&) {
                width: calc(100% - 80px);
                height: 280px;
            }
            @include mqXL(&) {
                display: none;
            }
        }
    }

    &__heading {
        color: $whiteColor;
        padding: 24px 0 192px;
        @include mqMD(&) {
            padding: 24/560 * 100% 0 192/560 * 100%;
        }
        @include mqLG(&) {
            padding: 0 0 40px 40px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        @include mqXL(&) {
            position: relative;
            top: 0;
            transform: none;
            display: flex;
            align-items: center;
            height: 360px;
            padding: 0 0 0 280/1200 * 100%;
            margin: 0 0 0 -280/1200 * 100%;
            background-color: $mainColor;
        }
    }
    &__body {
        &__inner {
            padding-left: 20px;
            @include mqLG(&) {
                padding: 0 80px;
            }
            @include mqXL(&) {
                max-width: 1360px;
                margin: 0 auto;
            }
        }
    }
    &__items {
        > a {
            display: block;
            position: relative;
            overflow: hidden;
            padding-top: 55.5%;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($blackColor, 0.3);
                z-index: 1;
                transition: background-color 300ms ease;
            }
            &:after {
                content: '\f04b';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 40px;
                height: 40px;
                border: 2px solid $whiteColor;
                border-radius: 50%;
                color: $whiteColor;
                text-align: center;
                line-height: 40px;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                z-index: 2;
                transition: transform 300ms ease;
                @include mqLG(&) {
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                }
                @include mqXL(&) {
                    width: 64px;
                    height: 64px;
                    line-height: 64px;
                }
            }
            &:hover {
                text-decoration: none;
                &:before {
                    background-color: rgba($blackColor, 0.5);
                }
                &:after {
                    transform: scale(1.2);
                }
            }
            > img {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

/* --- テイクアウトバナー ---
-------------------------------------------------------*/

.p-takeout-bnr {
    $self: &;

    &__heading {
        margin-bottom: 28px;
        @include mqXL(&) {
            margin-bottom: 48px;
        }
    }

    &__list {
        @include mqMD(&) {
            display: flex;
            margin: 0 -10px;
        }
        @include mqLG(&) {
            margin: 0 -12px;
        }
        @include mqXL(&) {
            margin: 0 -20px;
        }
        > li {
            @include mqMD(&) {
                width: 50%;
                padding: 0 10px;
            }
            @include mqLG(&) {
                padding: 0 12px;
            }
            @include mqXL(&) {
                padding: 0 20px;
            }
            > a {
                display: block;
                height: 120px;
                position: relative;
                overflow: hidden;
                @include mqMD(&) {
                    height: 0;
                    padding-top: 112/270 * 100%;
                }
                @include mqXL(&) {
                    padding-top: 0;
                    height: 200px;
                }
                span {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    color: $whiteColor;
                    text-align: center;
                    transform: translateY(-50%);
                }
                &:hover {
                    text-decoration: none;
                    #{$self}__img {
                        transform: scale(1.1, 1.1);
                    }
                }
            }
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        transition: transform 700ms ease;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($blackColor, 0.65);
        }
        &--takeout {
            background-image: url(./images/common/takeout-bnr.jpg);
        }
        &--online {
            background-image: url(./images/common/online-shop-bnr.jpg);
        }
    }

    &__takeout {
        margin-bottom: 16px;
        @include mqMD(&) {
            margin-bottom: 0;
        }
    }
}

/* --- 店舗案内 ---
-------------------------------------------------------*/

.p-shop-info {
    &__location {
        color: $whiteColor;
        position: relative;
        height: 240px;
        @include mqMD(&) {
            height: 220px;
        }
        @include mqLG(&) {
            height: 224px;
        }
        @include mqXL(&) {
            height: 480px;
        }
        &__img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(./images/common/location-s.jpg);
            background-size: cover;
            background-position: center center;
            @include mqMD(&) {
                background-image: url(./images/common/location-m.jpg);
                background-position: center center;
            }
            @include mqLG(&) {
                background-image: url(./images/common/location-l.jpg);
            }
            @include mqXL(&) {
                background-image: url(./images/common/location-xl.jpg);
            }
        }
    }
    &__heading {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 12/240 * 100% 20px;
        letter-spacing: 0.04em;
        @include mqSM(&) {
            padding: 16/240 * 100% 20px;
        }
        @include mqMD(&) {
            padding: 20px;
        }
        @include mqXL(&) {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1200px;
            padding: 0;
            display: flex;
            justify-content: flex-end;
        }
        > br {
            display: none;
            @include mqMD(&) {
                display: inline;
            }
        }
    }
    &__main {
        background-color: $whiteColor;
        &__heading {
            padding: 20px;
            @include mqLG(&) {
                padding: 32px 80px;
            }
            @include mqXL(&) {
                max-width: 1360px;
                margin: 0 auto;
            }
        }
        &__row {
            @include mqMD(&) {
                display: flex;
            }
        }
        &__img {
            height: 183px;
            background-image: url(./images/common/shop-info.jpg);
            background-size: cover;
            background-position: center center;
            @include mqSM(&) {
                height: 0;
                padding-top: 561/980 * 100%;
            }
            @include mqMD(&) {
                width: 50%;
                height: auto;
                padding-top: 0;
            }
            @include mqXL(&) {
                flex: 1;
                width: auto;
            }
        }
        &__body {
            background-color: rgba(#c0c3c9, 0.1);
            @include mqMD(&) {
                width: 50%;
            }
            @include mqXL(&) {
                width: 940/1920 * 100%;
            }
            &__inner {
                padding: 24px 20px 40px;

                @include mqLG(&) {
                    padding: 40px 20px 80px;
                }
                @include mqXL(&) {
                    padding: 64px 0;
                }
                @include mqXXL(&) {
                    width: 580/940 * 100%;
                }
            }
        }
        &__detail {
            @include mqSM(&) {
                max-width: 320px;
                margin: 0 auto;
            }
            @include mqXL(&) {
                max-width: none;
                display: table;
                width: 320px;
            }
            &__heading {
                margin-bottom: 28px;
            }
            &__address {
                line-height: 1.75;
            }
            &__tb {
                @include cf;
                line-height: 1.75;
                margin-bottom: 24px;
                > dt {
                    float: left;
                    &:after {
                        content: ':';
                        margin: 0 0.5em;
                    }
                }
                > dd {
                    overflow: hidden;
                    > a {
                        text-decoration: underline;
                        @include mqMD(&) {
                            pointer-events: none;
                            text-decoration: none;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            &__btn {
                max-width: 320px;
            }
            &__review {
                margin-top: 40px;
                &__heading {
                    margin-bottom: 24px;
                }
                &__btn {
                    max-width: 320px;
                }
            }
        }
    }
}

/*------------------------------------------------------

   --- 下層 共通 ---

-------------------------------------------------------*/

.p-main {
    &__header {
        position: relative;
        height: 348px;
        @include mqLG(&) {
            height: 440px;
        }
        @include mqXL(&) {
            height: 480px;
        }
        @include mqXXL(&) {
            height: 520px;
        }
        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
        }
        &__img {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 120%;
            background-size: cover;
            background-position: center center;
            transform: translateY(-50%);
            @include mqLG(&) {
                width: calc(100% - 56px);
                right: 0;
                left: auto;
            }
            @include mqXXL(&) {
                min-width: 1400px;
                width: 88.5%;
            }
            &--about {
                background-image: url(./images/about/header.jpg);
            }
            &--taiwan-tea {
                background-image: url(./images/menu/taiwan-tea/header.jpg);
            }
            &--taiwan-sweets {
                background-image: url(./images/menu/taiwan-sweets/header.jpg);
            }
            &--lunch {
                background-image: url(./images/menu/lunch/header.jpg);
            }
            &--drink {
                background-image: url(./images/menu/drink/header.jpg);
            }
            &--takeout {
                background-image: url(./images/menu/takeout/header.jpg);
            }
            &--online-shopping {
                background-image: url(./images/menu/onlineshop/header.jpg);
            }
            &--news {
                background-image: url(./images/news/header.jpg);
            }
            &--blog {
                background-image: url(./images/blog/header.jpg);
            }
            &--privacy-policy {
                background-image: url(./images/privacy-policy/header.jpg);
            }
            &--commerce {
                background-image: url(./images/commerce/header.jpg);
            }
            &--notfound {
                background-image: url(./images/notfound/header.jpg);
            }
        }

        &__box {
            position: absolute;
            bottom: 10px;
            left: 0;
            padding-left: 40px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            @include mqLG(&) {
                justify-content: flex-start;
                padding-left: 0;
                bottom: 0;
            }
            @include mqXXL(&) {
                min-width: 1400px;
                width: 88.5%;
                left: auto;
                right: 0;
            }
        }
        &__heading {
            line-height: 1.5;
            @include fontSize(24, 14);
            padding: 22px 0 23px;
            text-align: center;
            background-color: $bgColor;
            width: 100%;
            max-width: 320px;
            @include mqLG(&) {
                padding: 121px 0;
                max-width: 360px;
            }
            @include mqXL(&) {
                max-width: 440px;
                padding: 11.25% 0;
            }
            @include mqXXL(&) {
                @include fontSize(32);
                max-width: 640px;
                width: 480/1920 * 100%;
                padding: 0;
                padding: 176px 0;
                text-align: left;
            }
            > span {
                color: rgba($txtColor, 0.7);
                display: inline-block;
                white-space: nowrap;
                font-size: 60%;
                margin-left: 1em;
                letter-spacing: 0.04em;
            }
        }
    }

    &__body {
        padding: 40px 0 64px;
        position: relative;
        background-color: $bgColor;
        @include mqXL(&) {
            padding: 96px 0 120px;
        }
    }
}

/*------------------------------------------------------

   --- 無天茶坊について ---

-------------------------------------------------------*/

.p-about-greeting {
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 64px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $whiteColor;
        @include mqXL(&) {
            height: 80px;
        }
    }
}

.p-about-challenging {
    padding: 64px 0 0;
    background-color: $whiteColor;
    position: relative;
    @include mqXL(&) {
        padding: 80px 0 0;
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 64px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #ebeef0;
        @include mqXL(&) {
            height: 80px;
            background-color: $bgColor;
        }
    }
}

.p-about-application {
    padding: 64px 0;
    background-color: #ebeef0;
    @include mqXL(&) {
        padding: 80px 0;
        position: relative;
        z-index: 1;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 45%;
            background-color: $bgColor;
            z-index: -1;
        }
    }
}

.p-about-space {
    $self: &;
    padding: 64px 0;
    background-color: $whiteColor;
    @include mqXL(&) {
        padding: 80px 0;
    }
    &__gallery {
        @include mqSM(&) {
            padding: 0 20px;
        }
        @include mqLG(&) {
            padding: 0 56px;
        }
        @include mqXL(&) {
            padding: 0 80px;
            max-width: 1600px;
            margin: 0 auto;
        }
        .swiper-wrapper {
            backface-visibility: hidden;
        }
        .swiper-slide {
            overflow: hidden;
            &.swiper-slide-active {
                #{$self}__gallery__items__box__inner {
                    @include opacity(1);
                    transition: opacity 400ms ease 200ms;
                    @include mqLG(&) {
                        transition-delay: 500ms;
                    }
                }
            }
        }
        .swiper-button {
            padding-right: 20px;
            @include mqLG(&) {
                padding-right: 26px;
                position: absolute;
                right: 0;
                top: 500px;
            }
            @include mqXL(&) {
                top: 580px;
                padding-right: 80px;
            }
        }
        .swiper-pagination {
            text-align: left;
            padding-left: 36px;
            @include mqLG(&) {
                position: absolute;
                top: 500px;
                right: 123px;
                left: auto;
                bottom: auto;
                text-align: right;
                padding: 12px 40px;
                width: auto;
                background-color: $whiteColor;
            }
            @include mqXL(&) {
                top: 580px;
                padding: 12px 94px;
            }
        }
        &__items {
            &__img {
                backface-visibility: hidden;
                &__slide {
                    padding-top: 183/320 * 100%;
                    background-position: center;
                    background-size: cover;
                    @include mqLG(&) {
                        padding-top: 0;
                        height: 480px;
                    }
                    @include mqXL(&) {
                        height: 560px;
                    }

                    &--01 {
                        background-image: url(./images/about/gallery-img-01.jpg);
                    }
                    &--02 {
                        background-image: url(./images/about/gallery-img-02.jpg);
                    }
                    &--03 {
                        background-image: url(./images/about/gallery-img-03.jpg);
                    }
                    &--04 {
                        background-image: url(./images/about/gallery-img-04.jpg);
                    }
                }
            }
            &__body {
                padding: 0 20px;
                @include mqLG(&) {
                    padding: 0 26px;
                }
                @include mqXL(&) {
                    padding: 0 80px;
                    margin: 0 auto;
                    max-width: 1360px;
                }
            }
            &__box {
                position: relative;
                margin-top: -16px;
                padding: 24px 16px 20px;
                background-color: $whiteColor;
                z-index: 20;
                @include mqLG(&) {
                    max-width: 480px;
                    padding: 32px;
                    margin-top: -32px;
                }
                @include mqXL(&) {
                    padding: 40px;
                    max-width: 520px;
                    margin-top: -40px;
                }
                &__inner {
                    @include opacity(0);
                    transition: opacity 400ms ease;
                }
            }
            &__heading {
                margin-bottom: 16px;
                @include mqXL(&) {
                    @include fontSize(20);
                    margin-bottom: 32px;
                }
            }
        }
    }
}

.p-about-objective {
    padding: 64px 0 0;
    @include mqXL(&) {
        padding: 80px 0 0;
    }

    &__bg {
        padding-top: 64px;
        margin-top: -64px;
        background-color: $whiteColor;
        position: relative;
        @include mqXL(&) {
            padding-top: 80px;
            margin-top: -80px;
        }
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 64px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $bgColor;
            @include mqXL(&) {
                height: 80px;
            }
        }
    }

    &__ccworks {
        padding-top: 40px;
        @include mqXL(&) {
            padding-top: 96px;
        }
        &__gallery {
            display: flex;
            flex-wrap: wrap;
            margin-top: 28px;
            @include mqXL(&) {
                margin-top: 80px;
            }
            > li {
                width: 50%;
                @include mqSM(&) {
                    width: 25%;
                }
                > img {
                    display: block;
                }
            }
        }
    }

    &__relation {
        padding-top: 40px;
        @include mqXL(&) {
            padding-top: 96px;
        }
        &__bnr {
            @include mqXL(&) {
                max-width: 1360px;
                margin: 0 auto;
            }
            &__list {
                padding: 0 20px;
                margin-top: 20px;
                @include mqSM(&) {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 20px -8px 0;
                }
                @include mqMD(&) {
                    margin: 20px -10px 0;
                }
                @include mqLG(&) {
                    margin: 20px -12px 0;
                    padding: 0 80px;
                }
                @include mqXL(&) {
                    margin: 64px -16px 0;
                }
                > li {
                    @include mqSM(&) {
                        width: 50%;
                        padding: 0 8px;
                    }
                    @include mqMD(&) {
                        width: 1/3 * 100%;
                        padding: 0 10px;
                    }
                    @include mqLG(&) {
                        padding: 0 12px;
                    }
                    @include mqXL(&) {
                        padding: 0 16px;
                    }
                    &:not(:last-child) {
                        margin-bottom: 16px;
                        @include mqMD(&) {
                            margin-bottom: 0;
                        }
                    }
                    > a {
                        display: block;
                        transition: opacity 300ms ease;
                        &:hover {
                            @include opacity(0.7);
                        }
                    }
                }
            }
        }
    }

    &__vision {
        padding-top: 40px;
        @include mqXL(&) {
            padding-top: 96px;
        }
    }
}

.p-about-items {
    @include mqMD(&) {
        max-width: 640px;
    }
    &__heading {
        margin-bottom: 28px;
        @include mqXL(&) {
            @include fontSize(24);
            margin-bottom: 48px;
        }
    }

    &-child {
        @include mqMD(&) {
            max-width: 640px;
        }
        &__heading {
            margin-bottom: 20px;
            @include mqXL(&) {
                margin-bottom: 40px;
            }
        }
        &__pgh {
            &--vertical {
                margin-bottom: 20px;
                @include mqXL(&) {
                    margin-bottom: 28px;
                }
            }
        }
    }
}

.p-about-section-img {
    position: relative;
    margin-top: 28px;
    margin-left: 10px;
    padding-top: 184/310 * 100%;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    @include mqSM(&) {
        padding-top: 0;
        height: 200px;
    }
    @include mqMD(&) {
        height: 0;
        padding-top: 200/600 * 100%;
    }
    @include mqLG(&) {
        margin-left: 80px;
    }
    @include mqXL(&) {
        padding-top: 0;
        margin-top: 80px;
        height: 400px;
    }
    @media (min-width: 1360px) {
        margin-left: calc(((100vw - 1208px) / 2));
    }
    @include mqXXL(&) {
        height: 424px;
    }
    &--greeting {
        background-image: url(./images/about/section-img-01.jpg);
    }

    &--challenging {
        background-image: url(./images/about/section-img-02.jpg);
    }
    &--objective-01 {
        background-image: url(./images/about/section-img-03.jpg);
    }
    &--objective-02 {
        background-image: url(./images/about/section-img-04.jpg);
    }
}

/*------------------------------------------------------

   --- メニュー ---

-------------------------------------------------------*/

.p-menu-cat {
    &__desc {
        @include mqMD(&) {
            max-width: 640px;
        }
        &__heading {
            margin-bottom: 28px;
            @include mqXL(&) {
                margin-bottom: 48px;
            }
        }
        &__txt {
            > p {
                line-height: 1.75;
            }
            > p + p {
                margin-top: 1em;
            }
        }
    }
    &__search-box {
        margin: 40px 0 56px;
        @include mqXL(&) {
            margin-bottom: 64px;
        }
    }
    &__items {
        margin-bottom: 40px;
    }
    &__takeout {
        margin-top: 64px;
        @include mqXL(&) {
            margin-top: 96px;
        }
    }
}

/* --- 詳細 ---
-------------------------------------------------------*/

.p-menu-detail {
    $self: &;
    &__header {
        margin-bottom: 24px;
        @include mqMD(&) {
            margin-bottom: 40px;
        }
        @include mqXL(&) {
            margin-bottom: 80px;
        }
    }
    &__heading {
        margin-bottom: 28px;
        @include mqXL(&) {
            margin-bottom: 56px;
        }
    }
    &__gallery {
        position: relative;
        &__pause {
            position: absolute;
            bottom: 8px;
            right: 8px;
            text-align: center;
            z-index: 1;
            @include mqXL(&) {
                bottom: 24px;
                right: 24px;
            }
            > span {
                display: inline-block;
                color: $whiteColor;
                padding: 20px;
                line-height: 1.5;
                background-color: rgba($blackColor, 0.9);
                @include mqLG(&) {
                    padding: 32px;
                }
                @include mqXL(&) {
                    width: 320px;
                    @include fontSize(20);
                    padding: 70px 40px;
                }
            }

            @include mqLG(&) {
                bottom: 20px;
                right: 20px;
            }
        }
    }
    &__desc {
        margin-bottom: 40px;
        @include mqMD(&) {
            display: flex;
        }
        @include mqXL(&) {
            margin-bottom: 80px;
            justify-content: space-between;
        }

        &--single {
            @include mqMD(&) {
                display: block;
            }
            @include mqXL(&) {
                justify-content: normal;
            }
        }

        &__body {
            margin-bottom: 40px;
            @include mqMD(&) {
                flex: 1;
                margin-right: 40px;
                margin-bottom: 0;
            }
            @include mqXL(&) {
                margin-right: 80px;
            }
            &--full {
                @include mqMD(&) {
                    margin-right: 0;
                }
                @include mqXL(&) {
                    margin-left: 80px;
                }
                #{$self}__desc__price {
                    @include mqMD(&) {
                        max-width: 480px;
                    }
                }
                #{$self}__desc__tb {
                    @include mqMD(&) {
                        width: 480px;
                    }
                }
            }
        }
        &__price {
            display: flex;
            align-items: baseline;
            padding: 12px 0;
            margin-bottom: 28px;
            color: rgba($txtColor, 0.75);
            border-bottom: 1px solid $borderColor;
            @include mqXL(&) {
                padding: 14px 1em;
                margin-bottom: 48px;
            }
            > dt {
                width: 3em;
            }
            > dd {
                flex: 1;
                text-align: right;
                > span {
                    color: $txtColor;
                    font-weight: 700;
                    @include fontSize(18, 14);
                    margin-right: 8px;
                    @include mqXL(&) {
                        @include fontSize(24);
                    }
                }
            }
        }
        &__pgh {
            @include mqXL(&) {
                padding: 0 1em;
            }

            > p {
                &:not(:last-of-type) {
                    margin-bottom: 1.75em;
                }
            }
        }
        &__tb {
            margin-top: 15px;
            @include mqLG(&) {
                max-width: 480/640 * 100%;
            }
            @include mqXL(&) {
                margin-top: 48px;
            }
            > dl {
                display: flex;
                line-height: 1.5;
                > dt {
                    width: 5.5em;
                    padding: 13px 0 14px 1em;
                    border-bottom: 1px solid $borderColor;
                    position: relative;
                    &:after {
                        content: '：';
                        margin: 0 0.5em;
                        display: inline-block;
                        vertical-align: top;
                        position: absolute;
                        top: 13px;
                        right: 0;
                    }
                }
                > dd {
                    flex: 1;
                    padding: 13px 16px 14px 0;
                    border-bottom: 1px solid $borderColor;
                }
            }
        }

        &__other {
            @include mqMD(&) {
                width: 188px;
            }
            @include mqLG(&) {
                width: 480/1200 * 100%;
            }
            @include mqXL(&) {
                width: 480px;
            }
            &--vertical {
                @include mqLG(&) {
                    margin-top: 48px;
                }
                @include mqXL(&) {
                    margin-top: 56px;
                }
            }
            &__list {
                display: flex;
                margin: 0 -8px;
                @include mqMD(&) {
                    display: block;
                    margin: 0 0 -20px;
                }
                @include mqLG(&) {
                    display: flex;
                    margin: 0 -12px;
                }
                &--center {
                    justify-content: center;
                }
                > li {
                    width: 50%;
                    padding-left: 8px;
                    padding-right: 8px;
                    @include mqSM(&) {
                        max-width: 204px;
                    }
                    @include mqMD(&) {
                        width: 100%;
                        padding: 0;
                        margin-bottom: 20px;
                    }
                    @include mqLG(&) {
                        padding: 0 12px;
                        margin-bottom: 0;
                        max-width: 212px;
                    }
                    > a {
                        display: flex;
                        justify-content: center;
                        height: 120px;
                        padding: 16px 0;
                        color: $whiteColor;
                        @include mqLG(&) {
                            height: 140px;
                            padding: 26px 0;
                        }
                        @include mqXL(&) {
                            height: 172px;
                            padding: 28px 0;
                        }

                        &:hover {
                            text-decoration: none;
                        }

                        > span {
                            display: block;
                            text-align: center;
                            line-height: 1.5;
                            @include fontSize(13, 14);
                            position: relative;
                            @include mqXL(&) {
                                @include fontSize(16);
                            }
                            > i {
                                display: block;
                                @include fontSize(22, 14);
                                margin-bottom: 8px;
                                @include mqXL(&) {
                                    @include fontSize(28);
                                    margin-bottom: 16px;
                                }
                            }
                            > svg {
                                display: block;
                                position: absolute;
                                width: 22px;
                                bottom: 0;
                                right: 0;
                                @include mqXL(&) {
                                    width: 32px;
                                }
                            }
                        }
                    }
                    > div {
                        display: flex;
                        justify-content: center;
                        height: 120px;
                        padding: 16px 0;
                        color: $whiteColor;
                        @include mqLG(&) {
                            height: 140px;
                            padding: 26px 0;
                        }
                        @include mqXL(&) {
                            height: 172px;
                            padding: 28px 0;
                        }
                        > span {
                            display: block;
                            text-align: center;
                            line-height: 1.5;
                            @include fontSize(13, 14);
                            position: relative;
                            @include mqXL(&) {
                                @include fontSize(16);
                            }
                            > i {
                                display: block;
                                @include fontSize(22, 14);
                                margin-bottom: 8px;
                                @include mqXL(&) {
                                    @include fontSize(28);
                                    margin-bottom: 16px;
                                }
                            }
                        }
                    }
                }
                &__takeout {
                    > div {
                        background-color: #7e7c30;
                    }
                }
                &__shop {
                    > div {
                        background-color: #706a9f;
                    }
                }
                &__online {
                    > a {
                        background-color: #30597e;
                        transition: background-color 300ms ease;
                        &:hover {
                            background-color: lighten(#30597e, 5);
                        }
                    }
                }
            }
        }
    }

    &__how-to {
        margin-bottom: 40px;
        border: 1px solid $borderColor;
        @include mqMD(&) {
            display: flex;
            padding: 24px;
        }
        @include mqLG(&) {
            padding: 32px;
        }
        @include mqXL(&) {
            padding: 40px;
            margin-bottom: 100px;
        }
        &__body {
            padding: 20px 20px 0;
            @include mqMD(&) {
                padding: 0;
                flex: 1;
                margin-right: 40px;
            }
            @include mqXL(&) {
                margin-right: 120px;
            }
        }
        &__heading {
            margin-bottom: 28px;
            @include mqXL(&) {
                margin-bottom: 32px;
            }
        }
        &__img {
            padding: 16px;
            @include mqMD(&) {
                padding: 0;
                width: 40%;
            }
            @include mqXL(&) {
                width: 400px;
            }
            > img {
                display: block;
            }
        }
    }

    &__lunch-info {
        @include mqMD(&) {
            display: flex;
            margin-right: -20px;
        }
        @include mqLG(&) {
            margin-right: -40px;
            justify-content: space-between;
            align-items: baseline;
        }
        @include mqXL(&) {
            margin-bottom: 100px;
        }
        &__items {
            border: 1px solid $borderColor;
            padding: 20px;
            margin-bottom: 40px;
            @include mqMD(&) {
                width: 50%;
                margin-right: 20px;
            }
            @include mqLG(&) {
                margin-right: 40px;
                max-width: 640px;
                padding: 32px;
            }
            @include mqXL(&) {
                margin-bottom: 0;
                padding: 40px;
            }
            &__heading {
                margin-bottom: 28px;
                @include mqXL(&) {
                    margin-bottom: 32px;
                }
            }
        }
    }

    &__shopping-other {
        margin-bottom: 40px;
        margin-top: -13px;
        @include mqXL(&) {
            margin-bottom: 100px;
        }

        &__tb {
            @include mqSM(&) {
                display: table;
                width: 100%;
            }

            @include mqLG(&) {
                max-width: 960px;
                margin-left: auto;
                margin-right: auto;
            }
            > dl {
                line-height: 1.5;

                @include mqSM(&) {
                    display: table-row;
                }
                > dt {
                    padding: 13px 1em 0 0;
                    font-weight: 700;

                    @include mqSM(&) {
                        width: 20%;
                        display: table-cell;
                        padding: 13px 1em 14px 1em;
                        border-bottom: 1px solid $borderColor;
                    }
                }
                > dd {
                    padding: 13px 16px 14px 0;
                    border-bottom: 1px solid $borderColor;

                    @include mqSM(&) {
                        display: table-cell;
                    }
                }
            }
        }
    }
}

/*------------------------------------------------------

   --- お知らせ ---

-------------------------------------------------------*/

.p-news-list {
    &__search-box {
        margin-bottom: 36px;
        @include mqLG(&) {
            margin-bottom: 48px;
        }
    }
}

/*------------------------------------------------------

   --- ブログ ---

-------------------------------------------------------*/

.p-blog-list {
    &__search-box {
        margin-bottom: 36px;
        @include mqLG(&) {
            margin-bottom: 48px;
        }
    }
}

/*------------------------------------------------------

   --- プライバシーポリシー ---

-------------------------------------------------------*/

.p-privacy-policy {
    &__items {
        @include mqMD(&) {
            max-width: 800px;
            margin: 0 auto;
        }
        &:not(:last-of-type) {
            margin-bottom: 64px;
            @include mqXL(&) {
                margin-bottom: 80px;
            }
        }
        &__heading {
            margin-bottom: 28px;
            @include mqXL(&) {
                margin-bottom: 32px;
            }
        }
    }

    &__pgh {
        &--vertical {
            margin-bottom: 20px;
            @include mqXL(&) {
                margin-bottom: 28px;
            }
        }

        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

/*------------------------------------------------------

   --- 特定商取引法に基づく表記 ---

-------------------------------------------------------*/

.p-commerce {
    &__items {
        @include mqMD(&) {
            max-width: 800px;
            margin: 0 auto;
        }
        &:not(:last-of-type) {
            margin-bottom: 64px;
            @include mqXL(&) {
                margin-bottom: 80px;
            }
        }
        &__heading {
            margin-bottom: 28px;
            @include mqXL(&) {
                margin-bottom: 32px;
            }
        }
    }

    &__pgh {
        &--vertical {
            margin-bottom: 20px;
            @include mqXL(&) {
                margin-bottom: 28px;
            }
        }

        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

/*------------------------------------------------------

--- NotFound ---

-------------------------------------------------------*/

.p-notfound {
    &__heading {
        margin-bottom: 28px;
        @include mqXL(&) {
            margin-bottom: 32px;
        }
    }
    &__btn {
        max-width: 320px;
        margin-top: 40px;
        @include mqXL(&) {
            margin-top: 64px;
        }
    }
}

/* --- Footer ---
-------------------------------------------------------*/

.p-footer {
    background-color: #264765;
    padding: 96px 0 64px;
    @include mqLG(&) {
        margin-top: 64px;
    }
    @include mqXL(&) {
        padding: 96px 0;
    }
    &__inner {
        padding: 0 16px;
        position: relative;
        @include mqLG(&) {
            padding: 0 80px;
            max-width: 1120px;
            margin: 0 auto;
        }
        @include mqXL(&) {
            max-width: 1360px;
        }
    }
    &__main {
        @include mqLG(&) {
            display: flex;
            justify-content: space-between;
            //			align-items: flex-end;
        }
    }
    &__header {
        @include mqLG(&) {
            width: 240px;
            margin-right: 40px;
        }
    }
    &__body {
        @include mqLG(&) {
            flex: 1;
        }
    }
    &__logo {
        width: 256px;
        margin: 0 auto 24px;
        @include mqLG(&) {
            width: 240px;
        }
        @include mqXL(&) {
            width: 274px;
            margin-bottom: 40px;
        }

        > a {
            display: block;
        }
    }
    &__sns {
        display: flex;
        justify-content: center;
        margin: 0 -6px 30px;
        @include mqLG(&) {
            margin: 0;
            justify-content: flex-start;
        }
        > li {
            padding: 0 6px;
            @include mqLG(&) {
                padding: 0;
                margin-right: 1px;
            }
            > a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 44px;
                height: 44px;
                @include fontSize(20);
                color: $whiteColor;
                background-color: #30597e;
                transition: background-color 300ms ease;
                @include mqLG(&) {
                    width: 56px;
                    height: 56px;
                }
                //				> i {
                //					@include mqLG(&){
                //						@include fontSize(20);
                //					}
                //				}
                &:hover {
                    text-decoration: none;
                    background-color: rgba(#30597e, 0.5);
                }
            }
        }
    }
    &__body {
        margin-bottom: 64px;
        @include mqMD(&) {
            max-width: 640px;
            margin: 0 auto 64px;
        }
        @include mqLG(&) {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            margin-top: 52px;
        }
        @include mqXL(&) {
            max-width: none;
            margin-top: 0;
        }
    }
    &__link {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        @include mqLG(&) {
            margin: 0;
            display: block;
        }

        &-main {
            @include mqLG(&) {
                margin-right: 40px;
            }
        }
        &-menu {
            margin-top: 48px;
            @include mqLG(&) {
                margin-top: 0;
            }
            &__heading {
                color: $whiteColor;
                margin-bottom: 8px;
                @include mqMD(&) {
                    margin-bottom: 16px;
                }
                @include mqLG(&) {
                    padding: 14px 8px 15px;
                    margin-bottom: 0;
                }
            }
        }

        > li {
            width: 50%;
            padding: 0 8px;
            @include mqLG(&) {
                padding: 0 12px;
                width: 100%;
            }
            > a {
                display: block;
                color: $whiteColor;
                padding: 14px 0 15px;
                @include fontSize(13, 14);
                border-bottom: 1px solid rgba($whiteColor, 0.5);
                transition: background-color 300ms ease;
                @include mqSM(&) {
                    padding: 14px 8px 15px;
                }
                @include mqLG(&) {
                    border-bottom: none;
                }
                @include mqXL(&) {
                    @include fontSize(16);
                }

                &:hover {
                    text-decoration: none;
                    background-color: rgba(#30597e, 0.5);
                    @include mqXXL(&) {
                        text-decoration: underline;
                        background-color: transparent;
                    }
                }
            }
        }

        &--child {
            > li {
                > a {
                    &:before {
                        content: '-';
                        margin-right: 0.5em;
                    }
                }
            }
        }
    }
    &__cr {
        color: $whiteColor;
        display: block;
        text-align: center;
        @include fontSize(12, 14);
        @include mqLG(&) {
            margin-top: 64px;
        }
        @include mqXL(&) {
            @include fontSize(16);
            margin-top: 96px;
        }
    }
}
