@charset "utf-8";

.c-btn-type1 {
    display: block;
    border: 1px solid $blackColor;
    position: relative;
    padding: 15px 40px 15px 16px;
    text-align: center;
    transition: background-color 300ms ease, border 300ms ease, color 300ms ease;
    @include mqXL(&) {
        padding: 18px 40px 18px 16px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        width: 7px;
        height: 12px;
        @include arrow_right(100%, 100%);
        transition: 300ms ease;
    }
    &:hover {
        text-decoration: none;
        background-color: darken($bgColor, 60);
        border-color: darken($bgColor, 60);
        color: $whiteColor;
        &:after {
            @include arrow_right(100%, 100%, $whiteColor);
        }
    }
}

.c-more-btn {
    display: inline-block;
    padding: 5px 8px;
    border: 1px solid $blackColor;
}

.c-backtotop {
    width: 64px;
    position: absolute;
    top: -104px;
    right: 0;
    @include mqLG(&) {
        top: -124px;
    }
    > a {
        display: block;
        padding: 14px 0 15px;
        text-align: center;
        background-color: #2c587c;
        transition: background-color 300ms ease;
        > svg {
            transform: translateX(-2px);
        }
        &:hover {
            background-color: lighten(#2c587c, 5);
        }
    }
}

/* --- Shopify Button ---
-------------------------------------------------------*/
.shopify-buy-frame {
    &.is-change-zindex {
        z-index: 50 !important;
    }

    &--product {
        margin-bottom: 40px;
        @include mqMD(&) {
            margin-bottom: 28px;
        }
        @include mqXL(&) {
            margin-bottom: 48px;
        }
    }
}

.shopify-buy__btn-wrapper {
    padding: 0 20px;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;

    @include mqMD(&) {
        max-width: none;
        padding: 0;
    }
}

.shopify-buy__btn {
    width: 100%;
    display: block;
    color: $whiteColor;
    border: 1px solid darken($bgColor, 60);
    background-color: darken($bgColor, 60);
    position: relative;
    padding: 15px 40px 15px 16px;
    text-align: center;
    cursor: pointer;
    transition: background-color 300ms ease, border 300ms ease;
    @include mqXL(&) {
        padding: 18px 40px 18px 16px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        width: 7px;
        height: 12px;
        @include arrow_right(100%, 100%, $whiteColor);
        transition: 300ms ease;
    }
    &:hover {
        text-decoration: none;
        background-color: darken($bgColor, 50);
        border-color: darken($bgColor, 50);
    }
}

/* --- Paging ---
-------------------------------------------------------*/

.c-paging {
    @include mqSM(&) {
        max-width: 496px;
        padding: 0 20px;
        margin-left: auto;
        margin-right: auto;
    }
    @include mqMD(&) {
        max-width: 880px;
    }
    @include mqLG(&) {
        max-width: 1360px;
        padding: 0 80px;
    }
    &--vertical {
        margin-top: 40px;
        @include mqSM(&) {
            margin-top: 24px;
        }
        @include mqMD(&) {
            margin-top: 40px;
        }
        @include mqLG(&) {
            margin-top: 64px;
        }
        @include mqXL(&) {
            margin-top: 100px;
        }
    }
    &__inner {
        text-align: center;
        margin: 0 -5px;
        display: flex;
        padding: 0 20px;
        justify-content: center;
        @include mqLG(&) {
            margin: 0 -10px;
        }
    }
    &__prev {
        width: calc(50% - 10px);
        border: 1px solid $blackColor;
        > svg {
            stroke: $blackColor;
        }
        @include mqMD(&) {
            width: 44px;
            border-color: transparent;
        }
        @include mqXL(&) {
            width: 48px;
        }
        &--disable {
            border-color: rgba($borderColor, 0.3);
            background-color: rgba($borderColor, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            margin: 0 5px;
            font-weight: 700;
            > svg {
                stroke: $borderColor;
            }
            @include mqMD(&) {
                background-color: transparent;
            }
            @include mqLG(&) {
                margin: 0 10px;
            }
            @include mqXL(&) {
                height: 48px;
            }
        }

        &:not(.c-paging__prev--disable) {
            &:hover {
                background-color: darken($bgColor, 60);
                border-color: darken($bgColor, 60);
                > svg {
                    stroke: $whiteColor;
                }
            }
        }
    }
    &__next {
        width: calc(50% - 10px);
        border: 1px solid $blackColor;
        > svg {
            stroke: $blackColor;
        }
        @include mqMD(&) {
            width: 44px;
            border-color: transparent;
        }
        @include mqXL(&) {
            width: 48px;
        }
        &--disable {
            border-color: rgba($borderColor, 0.3);
            background-color: rgba($borderColor, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            margin: 0 5px;
            > svg {
                stroke: $borderColor;
            }
            @include mqMD(&) {
                background-color: transparent;
            }
            @include mqLG(&) {
                margin: 0 10px;
            }
            @include mqXL(&) {
                height: 48px;
            }
        }

        &:not(.c-paging__next--disable) {
            &:hover {
                background-color: darken($bgColor, 60);
                border-color: darken($bgColor, 60);
                > svg {
                    stroke: $whiteColor;
                }
            }
        }
    }
    &__current {
        border: 1px solid #264765;
        display: none;
        letter-spacing: normal;
        background-color: #264765;
        color: $whiteColor;
        font-weight: 700;
        @include mqMD(&) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            margin: 0 5px;
        }
        @include mqLG(&) {
            margin: 0 10px;
        }
        @include mqXL(&) {
            width: 48px;
            height: 48px;
        }
    }
    &__hellip {
        display: none;
        color: $txtColor;
        @include mqMD(&) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 44px;
            margin: 0 5px;
        }
        @include mqLG(&) {
            margin: 0 10px;
        }
        @include mqXL(&) {
            width: 48px;
            height: 48px;
        }
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        margin: 0 8px;
        font-weight: 700;
        transition: background-color 300ms, border-color 300ms, color 300ms;
        color: $txtColor;

        @include mqMD(&) {
            width: 44px;
            @include fontSize(16, 14);
        }
        @include mqXL(&) {
            height: 48px;
        }
        @include mqLG(&) {
            margin: 0 10px;
        }
        @include mqXL(&) {
            padding: 14px 0;
            @include fontSize(16);
        }
        > svg {
            transition: stroke 300ms ease;
        }
        &:hover {
            text-decoration: none;
        }

        &:not(.c-paging__prev):not(.c-paging__next) {
            display: none;
            border: 1px solid $borderColor;
            @include mqMD(&) {
                display: flex;
                width: 44px;
            }
            @include mqXL(&) {
                width: 48px;
            }

            > svg {
                stroke: $blackColor;
            }

            &:hover {
                color: $whiteColor;
                background-color: darken($bgColor, 60);
                border-color: darken($bgColor, 60);
            }
        }
    }
}

.c-pagenavi {
    @include mqMD(&) {
        max-width: 960px;
        margin: 0 auto;
    }
    &__share {
        padding: 20px 20px 24px;
        margin-bottom: 40px;
        border: 1px solid $borderColor;
        @include mqLG(&) {
            padding: 32px;
            display: flex;
            align-items: center;
        }
        @include mqXL(&) {
            margin-bottom: 48px;
        }
        &__heading {
            font-weight: 400;
            margin-bottom: 20px;
            @include mqLG(&) {
                width: 400/960 * 100%;
                margin-bottom: 0;
                text-align: center;
            }
        }
        &__list {
            display: flex;
            justify-content: center;
            margin: 0 -10px;
            @include mqLG(&) {
                width: 560/960 * 100%;
            }
            > li {
                padding: 0 10px;
                > a {
                    display: block;
                    @include fontSize(44, 14);
                }
            }
            &__fb {
                a {
                    color: #1877f2;
                }
            }
            &__tw {
                a {
                    color: #1da1f2;
                }
            }
            &__line {
                a {
                    color: #00b900;
                }
            }
        }
    }

    &__list {
        > li {
            > a {
                display: block;
                padding-top: 13px;
                padding-bottom: 13px;
                line-height: 1.5;
                position: relative;
                transition: background-color 300ms ease;
                @include mqXL(&) {
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
                &:hover {
                    text-decoration: none;
                    background-color: rgba($borderColor, 0.3);
                }
            }
        }
        &__prev {
            @include mqLG(&) {
                padding-right: 1/3 * 100%;
            }
            > a {
                padding-left: 44px;
                @include mqXL(&) {
                    padding-left: 64px;
                }
                &:after {
                    content: '';
                    display: block;
                    width: calc(100% - 20px);
                    height: 1px;
                    position: absolute;
                    border-bottom: 1px solid $borderColor;
                    left: 0;
                    bottom: 0;
                    @include mqLG(&) {
                        width: 100%;
                    }
                }
                > span {
                    display: inline-block;
                }
                > svg {
                    width: 28px;
                    position: absolute;
                    top: 18px;
                    left: 0;
                    @include mqXL(&) {
                        width: 32px;
                        top: 22px;
                    }
                }
            }
        }
        &__back {
            @include mqLG(&) {
                width: 1/3 * 100%;
                margin: 0 auto;
            }
            > a {
                text-align: center;
            }
            &--underline {
                border-bottom: 1px solid $borderColor;
            }
        }
        &__next {
            @include mqLG(&) {
                padding-left: 1/3 * 100%;
            }
            > a {
                text-align: right;
                padding-right: 44px;
                @include mqXL(&) {
                    padding-right: 64px;
                }
                &:after {
                    content: '';
                    display: block;
                    width: calc(100% - 20px);
                    height: 1px;
                    position: absolute;
                    border-bottom: 1px solid $borderColor;
                    top: 0;
                    right: 0;
                    @include mqLG(&) {
                        width: 100%;
                    }
                }
                > span {
                    display: inline-block;
                    text-align: left;
                }
                > svg {
                    width: 28px;
                    position: absolute;
                    top: 18px;
                    right: 0;
                    @include mqXL(&) {
                        width: 32px;
                        top: 22px;
                    }
                }
            }
        }
    }
}
