@charset "utf-8";

/* --- カードアイテム1 ---
-------------------------------------------------------*/

.c-card-items-type1 {
    $self: &;
    > a {
        display: block;
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 1px;
            left: 0;
            border-bottom: 1px solid $borderColor;
            z-index: 1;
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 1px;
            right: 0;
            border-bottom: 1px solid $mainColor;
            transform: scaleX(0.5);
            transform-origin: right center;
            transition: 300ms transform ease;
            z-index: 2;
        }
        &:hover {
            text-decoration: none;

            &:after {
                transform: scaleX(1);
            }

            #{$self}__header {
                &:before {
                    @include opacity(0.2);
                }
            }

            #{$self}__btn {
                background-color: darken($bgColor, 60);
                border-color: darken($bgColor, 60);
                color: $whiteColor;
            }
        }
    }

    &__header {
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $whiteColor;
            @include opacity(0);
            transition: opacity 300ms ease;
        }
        > img {
            display: block;
        }
    }

    &__body {
        position: relative;
        margin: -16px 16px 0 0;
        padding: 24px 20px 72px 0;
        background-color: $bgColor;
        @include mqXL(&) {
            padding: 24px 16px 96px 0;
        }
    }

    &__heading {
        margin-bottom: 16px;
        @include mqXL(&) {
            margin-bottom: 24px;
        }
    }

    &__btn {
        position: absolute;
        bottom: 24px;
        right: 20px;
        transition: color 300ms ease, background-color 300ms ease, border 300ms ease;
        @include mqXL(&) {
            right: 16px;
            bottom: 32px;
        }
    }
}

/* --- カードアイテム2 ---
-------------------------------------------------------*/

.c-card-items-type2 {
    $self: &;
    > a {
        position: relative;
        display: block;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 1px;
            left: 0;
            border-bottom: 1px solid $borderColor;
            z-index: 1;
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 1px;
            right: 0;
            border-bottom: 1px solid $mainColor;
            transform: scale(0.5, 1);
            transform-origin: right center;
            transition: 300ms transform ease;
            z-index: 2;
        }
        &:hover {
            text-decoration: none;
            &:after {
                transform: scale(1, 1);
            }

            #{$self}__header {
                &:before {
                    @include opacity(0.2);
                }
            }

            #{$self}__cat {
                background-color: rgba($txtColor, 0.6);
            }
        }
    }
    &--more {
        > a {
            padding-bottom: 72px;
            @include mqXL(&) {
                padding-bottom: 84px;
            }

            &:hover {
                #{$self}__btn {
                    background-color: darken($bgColor, 60);
                    border-color: darken($bgColor, 60);
                    color: $whiteColor;
                }
            }
        }
    }

    &__header {
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $whiteColor;
            @include opacity(0);
            transition: opacity 300ms ease;
        }
        > img {
            display: block;
        }
    }
    &__pause {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        color: $whiteColor;
        text-align: center;
        line-height: 1.75;
        padding: 16px 10px;
        @include fontSize(13, 14);
        background-color: rgba($blackColor, 0.8);
        @include mqMD(&) {
            letter-spacing: -0.04em;
            padding: 16px 4px;
        }
        @include mqLG(&) {
            padding: 20px 4px;
        }
        @include mqXL(&) {
            @include fontSize(16);
            padding: 40px 8px;
        }
    }
    &__cat {
        position: absolute;
        bottom: 0;
        right: 0;
        transition: background-color 300ms ease;
    }
    &__heading {
        padding: 20px 0;
        @include mqXL(&) {
            padding: 24px 0;
        }

        &__price {
            margin-top: 0.5em;
        }
    }
    &__pgh {
        margin-top: -0.25em;
    }
    &__btn {
        position: absolute;
        bottom: 24px;
        right: 20px;
        transition: color 300ms ease, background-color 300ms ease, border 300ms ease;
        @include mqXL(&) {
            bottom: 32px;
            right: 0;
        }
    }
}

/* --- メディア ---
-------------------------------------------------------*/

.c-media {
    $self: &;
    @include mqXL(&) {
        max-width: 1360px;
        margin: 0 auto;
    }
    &__inner {
        @include mqMD(&) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px -32px;
            padding: 0 20px;
        }
        @include mqLG(&) {
            padding: 0 80px;
            margin: 0 -12px -32px;
        }
        @include mqXL(&) {
            margin: 0 -16px -80px;
        }
    }
    &__items {
        @include mqMD(&) {
            width: 1/3 * 100%;
            padding: 0 10px;
            margin-bottom: 32px;
        }
        @include mqLG(&) {
            padding: 0 12px;
        }
        @include mqXL(&) {
            padding: 0 16px;
            margin-bottom: 80px;
        }
        > a {
            display: flex;
            align-items: flex-start;
            padding: 20px;
            position: relative;
            @include mqMD(&) {
                display: block;
                padding: 0 0 24px;
            }
            @include mqXL(&) {
                padding: 0 0 32px;
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 1px;
                left: 0;
                border-bottom: 1px solid $borderColor;
                z-index: 1;
            }
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 1px;
                left: 0;
                border-bottom: 1px solid $mainColor;
                transform: scale(0.36875, 1);
                transform-origin: left center;
                transition: transform 300ms ease;
                z-index: 2;
            }
            &:hover {
                text-decoration: none;
                &:after {
                    transform: scale(1, 1);
                }

                #{$self}__items__header {
                    &:before {
                        @include opacity(0.2);
                    }
                }
            }
        }

        &__header {
            max-width: 124px;
            width: 124/280 * 100%;
            margin-right: 16px;
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $whiteColor;
                @include opacity(0);
                transition: opacity 300ms ease;
                z-index: 1;
            }
            @include mqMD(&) {
                max-width: none;
                width: 100%;
                margin-right: 0;
                margin-bottom: 16px;
            }
            @include mqXL(&) {
                margin-bottom: 20px;
            }

            &__inner {
                > img {
                    display: block;
                }

                &--objectfit {
                    overflow: hidden;
                    position: relative;
                    padding-top: 261/379 * 100%;
                    > img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        &__body {
            flex: 1;
        }
        &__info {
            @include mqXL(&) {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
            }
        }
        &__cat {
            margin-bottom: 8px;
            @include mqXL(&) {
                margin: 0 16px 0 0;
            }
        }
        &__post-date {
            margin-bottom: 12px;
            font-weight: 700;
            color: rgba($blackColor, 0.75);
            @include fontSize(12, 14);
            @include mqMD(&) {
                margin-bottom: 16px;
            }
            @include mqXL(&) {
                margin-bottom: 0;
            }
        }

        &__date {
            @include mqXL(&) {
                margin-bottom: 8px;
            }
        }
    }
}

.c-media-detail {
    &__header {
        margin-bottom: 28px;
        @include mqXL(&) {
            margin-bottom: 56px;
        }
        &__info {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
        }
    }
    &__date {
        color: rgba($blackColor, 0.75);
        font-weight: 700;
        margin-right: 16px;
    }
    &__body {
        margin-bottom: 40/14 - 1.75 + em;
        word-wrap: break-word;
        @include mqXL(&) {
            margin-bottom: 80/16 - 1.75 + em;
        }

        h1,
        h2,
        h3,
        h4 {
            @include fontSize(18, 14);
            line-height: 1.5;
            margin: 2em 0 1.25em;
            @include mqXL(&) {
                @include fontSize(20);
            }
        }

        h5 {
            @include fontSize(16, 14);
            line-height: 1.5;
            margin: 2em 0 1.25em;
            @include mqXL(&) {
                @include fontSize(18);
            }
        }

        h6 {
            @include fontSize(14, 14);
            line-height: 1.5;
            margin: 2em 0 1.25em;
            @include mqXL(&) {
                @include fontSize(16);
            }
        }

        .wp-caption {
            display: table;
            position: relative;
            margin-bottom: 1.75em;
            &:after {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.3) 100%)
                    repeat 0 0;
                z-index: 1;
            }
            &-text {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                color: $whiteColor;
                padding: 0.5em;
                text-align: right;
                z-index: 2;
                @include mqMD(&) {
                    padding: 1em 1.75em;
                }

                > span {
                    display: inline-block;
                    text-align: left;
                }
            }

            &.is-loading {
                &:after {
                    content: none;
                }
                .wp-caption-text {
                    display: none;
                }
            }
        }

        a {
            color: $mainColor;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }

        img {
            max-width: 100%;
            width: auto;
        }

        p {
            line-height: 1.75;
            margin-bottom: 1.75em;
        }

        blockquote {
            padding: 2em 2em 0.25em;
            border-top: 1px solid $borderColor;
            border-bottom: 1px solid $borderColor;
            margin-bottom: 1.75em;
            &:before {
                display: block;
                content: '\f10d';
                font-weight: 700;
                margin-bottom: 1em;
                font-size: 120%;
                font-family: 'Font Awesome 5 Free';
            }
            &:after {
                display: block;
                content: '\f10e';
                font-weight: 700;
                margin-top: 1em;
                margin-bottom: 1.75em;
                font-size: 120%;
                text-align: right;
                font-family: 'Font Awesome 5 Free';
            }
        }

        ul {
            margin-bottom: 1.75em;
            > li {
                line-height: 1.5;
                margin-bottom: 0.5em;
                position: relative;
                padding-left: 1em;
                &:before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $borderColor;
                    position: absolute;
                    top: 0.5em;
                    left: 0;
                }
            }
        }

        ol {
            counter-reset: num;
            margin-bottom: 1.75em;
            > li {
                counter-increment: num;
                padding-left: 1.75em;
                line-height: 1.5;
                margin-bottom: 0.5em;
                position: relative;
                &:before {
                    content: counter(num) ' ).';
                    width: 1.75em;
                    font-weight: 700;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: darken($borderColor, 20);
                }
            }
        }

        table {
            width: 100%;
            line-height: 1.5;
            margin-bottom: 1.75em;
            table-layout: fixed;
            border: 4px solid $borderColor;
            th {
                border: 1px solid $borderColor;
            }
            td {
                padding: 1em;
                border: 1px solid $borderColor;
            }
        }

        hr {
            border-top: 1px solid $borderColor;
            border-bottom: none;
            border-left: none;
            border-right: none;
            margin: 1.75em 0;
        }

        em {
            font-style: italic;
        }
    }
}

/* --- カテゴリなどの検索 ---
-------------------------------------------------------*/

.c-search-box {
    @include mqMD(&) {
        max-width: 640px;
    }
    &__btn {
        &--row {
            @include mqSM(&) {
                display: flex;
                margin: 0 -8px;
                > li {
                    width: 50%;
                    padding: 0 8px;
                }
            }
            @include mqMD(&) {
                margin: 0 -10px;
                > li {
                    max-width: 320px;
                    padding: 0 10px;
                }
            }
            @include mqLG(&) {
                margin: 0 -12px;
                > li {
                    padding: 0 12px;
                }
            }
            @include mqXL(&) {
                margin: 0 -16px;
                > li {
                    padding: 0 16px;
                }
            }
        }
        > li {
            &:not(:last-child) {
                margin-bottom: 16px;
                @include mqSM(&) {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__trigger {
        display: block;
        max-width: 320px;
        width: 100%;
        background-color: transparent;
        border: 1px solid $blackColor;
        position: relative;
        padding: 15px 40px 15px 16px;
        text-align: center;
        transition: background-color 300ms ease;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            margin: auto;
            width: 12px;
            height: 7px;
            @include arrow_down(100%, 100%);
        }
        &:hover {
            background-color: darken($bgColor, 60);
            border-color: darken($bgColor, 60);
            color: $whiteColor;
            &:after {
                @include arrow_down(100%, 100%, $whiteColor);
            }
        }
        &.is-active {
            background-color: darken($bgColor, 60);
            border-color: darken($bgColor, 60);
            color: $whiteColor;
            &:after {
                transform: rotate(180deg);
                @include arrow_down(100%, 100%, $whiteColor);
            }
        }
    }
    &__detail {
        display: none;
        margin-top: 20px;
        padding: 16px 16px 32px;
        border: 2px solid $borderColor;
        @include mqMD(&) {
            padding: 24px 20px 28px;
        }
        @include mqLG(&) {
            padding: 28px 24px 32px;
        }
        @include mqXL(&) {
            padding: 28px 32px 36px;
        }
    }
    &__catlist {
        &--vertical {
            margin-bottom: 24px;
            @include mqMD(&) {
                margin-bottom: 28px;
            }
            @include mqLG(&) {
                margin-bottom: 32px;
            }
            @include mqXL(&) {
                margin-bottom: 36px;
            }
        }
        @include mqMD(&) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
        }
        @include mqLG(&) {
            margin-left: -12px;
            margin-right: -12px;
        }
        @include mqXL(&) {
            margin-left: -16px;
            margin-right: -16px;
        }
        > li {
            @include mqMD(&) {
                width: 50%;
                padding: 0 10px;
            }
            @include mqLG(&) {
                padding: 0 12px;
            }
            @include mqXL(&) {
                padding: 0 16px;
            }
            > a {
                display: block;
                padding: 14px 8px;
                position: relative;
                border-bottom: 1px solid $borderColor;
                transition: background-color 300ms ease;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 8px;
                    bottom: 0;
                    margin: auto;
                    width: 6px;
                    height: 10px;
                    @include arrow_right(100%, 100%);
                }
                &:hover {
                    text-decoration: none;
                    background-color: rgba($mainColor, 0.1);
                }
            }
            &.is-current,
            &.current-cat {
                > a {
                    pointer-events: none;
                    color: rgba($txtColor, 0.75);
                    background-color: rgba($borderColor, 0.3);
                    &:after {
                        @include arrow_right(100%, 100%, rgba($txtColor, 0.75));
                    }
                }
            }
        }
    }
}

/* --- 関連メニュー ---
-------------------------------------------------------*/

.c-relation-menu {
    background-color: $whiteColor;
    margin: 40px 0 -64px;
    padding: 40px 0 64px;
    @include mqXL(&) {
        margin: 100px 0 -120px;
        padding: 80px 0 120px;
    }
    &-col4 {
        &__heading {
            margin-bottom: 28px;
            @include mqXL(&) {
                margin-bottom: 48px;
            }
        }
    }

    &-col2 {
        @include mqMD(&) {
            display: flex;
            margin: 0 -10px;
        }
        @include mqLG(&) {
            margin: 0 -12px;
        }
        @include mqXL(&) {
            margin: 0 -16px;
        }
        &__items {
            margin-top: 48px;
            @include mqMD(&) {
                width: 50%;
                padding: 0 10px;
            }
            @include mqLG(&) {
                padding: 0 12px;
            }
            @include mqXL(&) {
                margin-top: 72px;
                padding: 0 16px;
            }
            &__heading {
                margin-bottom: 28px;
                @include mqXL(&) {
                    margin-bottom: 48px;
                }
            }
        }
    }
}

/* --- BackToTop ---
-------------------------------------------------------*/

.c-back-to-top {
    @include mqXL(&) {
        height: 56px;
        margin-top: -56px;
    }
}

/* --- カテゴリ ---
-------------------------------------------------------*/

.c-cat {
    display: inline-block;
    padding: 5px 8px;
    color: $whiteColor;
    background-color: $blackColor;
}

/* --- テキスト ---
-------------------------------------------------------*/

.c-pgh {
    line-height: 1.75;
}

// iframeのレスポンシブ対応
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
