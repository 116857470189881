@charset "utf-8";
// core settings

/* --- core setteings ---
-------------------------------------------------------*/

html {
    box-sizing: border-box;
    // ブレークポイント320pxで14px。1279pxで16px
    font-size: calc((14 / 16) * 1em + 2 * (100vw - 320px) / 959);

    @include mqXL(&) {
        @include fontSize(16);
    }
}

* {
    margin: 0;
    padding: 0;
}

*,
::after,
::before {
    background-repeat: no-repeat;
    box-sizing: inherit;
}

::before,
::after {
    text-decoration: inherit;
    vertical-align: inherit;
}

body {
    font-family: $serif;
    color: $txtColor;
    font-weight: 400;
    background-color: $bgColor;
    overflow-wrap: break-word;
    word-wrap: break-word;

    &.is-ovhidden {
        overflow: hidden;

        //		height: 100%;
        @include mqXXL(&) {
            overflow: visible !important;
        }
    }
}

::selection {
    color: lighten($txtColor, 10);
    background-color: lighten($borderColor, 15);
}

a {
    color: $txtColor;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    transition: none !important;
}

h1 {
    margin: 0;
}

ol,
ul {
    list-style: none;
}

iframe,
img,
textarea {
    vertical-align: bottom;
}

img {
    width: 100%;
    height: auto;
}

textarea {
    resize: vertical;
}

address {
    font-style: normal;
}

input,
button {
    font: inherit;
    color: inherit;
}

em {
    font-weight: 700;
    font-style: normal;
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;
}

.resize-animation-stopper * {
    animation: none !important;
    transition: none !important;
}
